import React, {useState} from "react";
import SEO from "../components/seo";
import AboveFold from "../components/aboveFold";
import StakesBg from "../components/stakesSidingBg";
import ContactForm from "../components/contactForm";
import Layout from "../components/layout";
import Hr from "../components/hr";
import PlanSteps from "../components/planSteps";
import Review from "../components/review";
import StyledBackgroundSection from "../components/backgroundSiding";
import SectionHeading from "../components/sectionHeading";
import { 
  Button, 
  Container, 
  Row, 
  Col, 
  Card,
  CardGroup
} from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faArrowAltCircleUp, 
  faArrowAltCircleDown, 
} from '@fortawesome/free-regular-svg-icons';
import { useStaticQuery, graphql, Link } from "gatsby";
import Img from "gatsby-image";

const Siding = () => {
    const data = useStaticQuery(graphql`
    query {
    
      work1: file(relativePath: { eq: "siding-20221010-0784.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      },
      work2: file(relativePath: { eq: "siding-20221010-0783.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      },
      work3: file(relativePath: { eq: "siding-20221010-0782.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      },
      work4: file(relativePath: { eq: "siding-20221010-0786.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      },
      work5: file(relativePath: { eq: "siding-20221010-0787.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      },
      work6: file(relativePath: { eq: "siding-20221010-0780.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      },
      work7: file(relativePath: { eq: "siding-20221010-0790.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      },
      wideDivider1: file(relativePath: { eq: "siding7.webp" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      },
      wideDivider2: file(relativePath: { eq: "siding3.webp" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      },
      wideDivider3: file(relativePath: { eq: "siding5.webp" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      },
      wideDivider4: file(relativePath: { eq: "siding2.webp" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      },
      wideDivider5: file(relativePath: { eq: "house2.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      },
      wideDivider6: file(relativePath: { eq: "siding10.webp" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      },
      stakes1: file(relativePath: { eq: "instagram_siding_bad_2.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      },
      certainteedLogo: file(relativePath: { eq: "certainteed.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      },
      paragraph1: file(relativePath: { eq: "siding1.webp" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      },
      paragraph2: file(relativePath: { eq: "siding-20221010-0783.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      },
        allSidingImgDataJson {
          edges {
            node {
              id
              name
              thumbnail {
                childImageSharp {
                  fixed(width: 200) {
                    ...GatsbyImageSharpFixed
                  }
                }
              }
              description
            }
          }
        }
    }
  `);
  
                
  const [contactTxt] = useState('');
  
  // All available shingle styles (loaded from JSON via GraphQL)
  // This data structure is just repackaging the GraphQL result 
  const thumbnails = Object.values(data.allSidingImgDataJson.edges).map( (item,key) => {
      return {
        id: item.node.id,
        name: item.node.name,
        thumbnail: item.node.thumbnail.childImageSharp.fixed,
        description: item.node.description,
      }
  });

  return (
  <Layout>
    <SEO title="Vinyl Siding Contractor" path='vinyl-siding-contractor'/>
    <StyledBackgroundSection/>
    <AboveFold
      pretitle='Vinyl '
      title='Siding'
      subtitle="Never paint your house again"
      >
      {/* TODO LEADGEN 
      <div className="cg-leadLink">...or learn about <Link to="/common-roofing-and-siding-problems" className='cg-linkLight'>Common Siding Problems</Link></div>
      */}
    </AboveFold>
    
    <Hr thick/>
    
    <main id='main'>
      <StakesBg id='stakes' className='cg-sectionShaded'>
        <div className='cg-section'>
          <Container fluid id='cg-stakesBox'>
            <Row>
              <Col>
                <SectionHeading>Time to Paint Again?!</SectionHeading>
              </Col>
            </Row>
            <Row>
              <Col sm style={{textAlign:'left'}}>
                <p>Do you see the telltale signs that it's time to paint again: chipping, fading, or&nbsp;peeling?</p>
                
                <p>You could let it go and do nothing. But, an unattractive home is a detractor to the entire neighborhood.
                It can lower the property value of your home and also those around it. And it's just not&nbsp;nice.</p>
                
              </Col>
              <Col sm>
                <div role="img" aria-labelledby="stakes1Image">
                  <Img fluid={data.stakes1.childImageSharp.fluid} className="cg-picture"/>
                  <p id='stakes1Image' className='cg-caption'>Old Siding</p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <p>Instead of spending money on painting (again and again), there's a better&nbsp;option...</p>
                <Button className="cg-dark" href="#contactForm" >Get a Quote</Button>
              </Col>
            </Row>
          </Container>
        </div>
      </StakesBg>
      
      <Hr thick style={{backgroundColor:'#ddd'}}/>
      
      <div id='value' className='cg-sectionLight'>
        <div className='cg-section'>
          <Container fluid>
            <Row>
              <Col>
                <SectionHeading>Siding that Lasts</SectionHeading>
                <p>
                  When you choose MLB Contracting to install vinyl siding, you get <strong>quality materials</strong> that
                  are <strong>installed right</strong> to make your home look great. <strong>Vinyl siding</strong>
                  is easier to maintain - no&nbsp;painting&nbsp;needed!
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <CardGroup>
                  <Card>
                    <Card.Body>
                      <Card.Title>Trusted Materials</Card.Title>
                      <Card.Text>
                        Your house will be protected for decades with the best 
                        brand in siding, covered by a solid manufacturer warranty 
                        against weather damage&nbsp;and&nbsp;fade.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                  <Card>
                    <Card.Body>
                      <Card.Title>Certified Installation</Card.Title>
                      <Card.Text>
                        Good materials don't work if they're installed wrong. We 
                        have the training, certification and experience to get 
                        your <strong>job&nbsp;done&nbsp;right</strong>.
                      </Card.Text>
                    </Card.Body>
                  </Card>
                  <Card>
                    <Card.Body>
                      <Card.Title>A Beautiful Home</Card.Title>
                      <Card.Text>
                        You'll smile at the fresh curb-appeal that will greet you 
                        every time you come home. (Your neighbors might thank you,&nbsp;too.)
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </CardGroup>
              </Col>
            </Row>
            <Row>
              <Col style={{ paddingTop:'2rem' }} sm>
                <Button className="cg-dark" href="#contactForm" >Get a Quote</Button>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
        
      <Hr img={data.wideDivider1.childImageSharp.fluid} />
      
      <div id='guide' style={{ textAlign:'center' }}  className='cg-sectionShaded'>
        <div className='cg-section'>
          <SectionHeading>Choosing the Right Contractor</SectionHeading>
          <Container fluid>
            <Row>
              <Col>
                <p>We understand the uncertainty of choosing a siding contractor.</p>
                <div style={{textAlign:'left', maxWidth:'28em', margin:'auto'}}>
                  <ul>
                    <li>Will they charge a fair price?</li>
                    <li>Will their work stand the test of time?</li>
                    <li>Will my house look nice when the job is done?</li>
                  </ul>
                </div>
                <p>
                  Unfortunately, we know <strong>these problems exist</strong>. That's 
                  why we've invested our time and money in becoming CertainTeed
                  certified siding installers: so you can <strong>continue with confidence</strong>. 
                  It's also why we've collected reviews from our customers over 
                  the past {new Date().getFullYear() - 2005} years of service in
                  the Utica / Rome area: so you can be certain of the quality
                  service&nbsp;you&nbsp;will&nbsp;get.
                </p>
              </Col>
            </Row>
            </Container>
        </div>
      </div>
        
      <Hr/>
      
      <div id='guide' style={{ textAlign:'center' }}  className='cg-sectionLight'>
        <div className='cg-section'>
          <SectionHeading>Our Work</SectionHeading>
          <Container fluid>
            <Row>
              <Col md style={{marginTop:'2rem'}}>
                  <Card>
                    <Card.Body>
                      <Card.Text>
                        These photos are from a recent project. To see more, visit the portfolio.
                      </Card.Text>
                      <Link className='btn btn-secondary' to='/portfolio-siding'>Portfolio</Link>
                    </Card.Body>
                  </Card>
              </Col>
              <Col md style={{marginTop:'2rem'}}>
                  <Card><Img fluid={data.work1.childImageSharp.fluid} className="card-img"/></Card>
              </Col>
            </Row>
            <Row>
              <Col md style={{marginTop:'2rem'}}>
                  <Card><Img fluid={data.work2.childImageSharp.fluid} className="card-img"/></Card>
              </Col>
              <Col md style={{marginTop:'2rem'}}>
                  <Card><Img fluid={data.work3.childImageSharp.fluid} className="card-img"/></Card>
              </Col>
            </Row>
            <Row>
              <Col md style={{marginTop:'2rem'}}>
                  <Card><Img fluid={data.work4.childImageSharp.fluid} className="card-img"/></Card>
              </Col>
              <Col md style={{marginTop:'2rem'}}>
                  <Card><Img fluid={data.work5.childImageSharp.fluid} className="card-img"/></Card>
              </Col>
            </Row>
            <Row>
              <Col md style={{marginTop:'2rem'}}>
                  <Card><Img fluid={data.work6.childImageSharp.fluid} className="card-img"/></Card>
              </Col>
              <Col md style={{marginTop:'2rem'}}>
                  <Card><Img fluid={data.work7.childImageSharp.fluid} className="card-img"/></Card>
              </Col>
            </Row>
            
            <Row>
              <Col style={{ paddingTop:'2rem' }} sm>
                <Link className='btn btn-secondary' to='/portfolio-siding'>See Portfolio</Link>
              </Col>
              <Col style={{ paddingTop:'2rem' }} sm>
                <Button className="cg-dark" href="#contactForm" >Get a Quote</Button>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
        
      <Hr/>
      
      <div id='guide' style={{ textAlign:'center' }}  className='cg-sectionShaded'>
        <div className='cg-section'>
          <SectionHeading>Reviews</SectionHeading>
          <Container fluid>
            <Row>
              <Col>
                <CardGroup>
                  <Review title='Outstanding!' name='John C.' thumbtack>
                    Put in new roof, siding, and tiled house. Did an <strong>outstanding job</strong>! Thank&nbsp;you!
                  </Review>
                  <Review title='Great contractor' name='Richard'>
                    Great contractor. Great work. Great communication. Hired to install 12 new windows and install new vinyl siding.
                  </Review>
                </CardGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <CardGroup>
                  <Review title='Done in no time' name='John S.' thumbtack>
                    Replaced my whole roof and siding for a very decent price.
                    Came in, knocked the job off, and was <strong>done in no time</strong>. Excellent&nbsp;work. 
                  </Review>
                  <Review title='Excellent' name='Peter D.' thumbtack>
                    MLB Contracting was an <strong>excellent choice</strong> for our siding project. They were hard working non-stop individuals who worked together as a team in completing [the] job at hand. Already are [making plans] with them for future home improvement projects!
                  </Review>
                </CardGroup>
              </Col>
            </Row>
            <Row>
              <Col style={{ paddingTop:'2rem' }}>
                <p>You too, can freshen up your home with siding&nbsp;that&nbsp;lasts.</p>
                <p>To get service like this <FontAwesomeIcon icon={faArrowAltCircleUp} /> contact&nbsp;us!&nbsp;<FontAwesomeIcon icon={faArrowAltCircleDown} /> </p>
              </Col>
            </Row>
            <Row>
              <Col style={{ paddingTop:'2rem' }} sm>
                <Link className='btn btn-secondary' to='/reviews#main'>See More Reviews</Link>
              </Col>
              <Col style={{ paddingTop:'2rem' }} sm>
                <Button className="cg-dark" href="#contactForm" >Get a Quote</Button>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      
      <Hr img={data.wideDivider3.childImageSharp.fluid} style={{ maxHeight: '30vh'}} 
        imgStyle={{objectPosition: 'bottom center', top:'initial', bottom:0 }}/>
      
      
      <div id='certainteed'  className='cg-sectionLight'>
        <div className='cg-section'>
          <Container fluid>
            <Row>
              <Col>
                <SectionHeading>CertainTeed Vinyl Siding</SectionHeading>
              </Col>
            </Row>
            <Row>
              <Col>
                <p>
                  CertainTeed vinyl siding offers exceptional durability with an 
                  industry-leading spectrum of fade-resistant colors and a Lifetime 
                  Limited Warranty. Choose from maintenance-free classic clapboard, 
                  shiplap, beaded, shakes or shingle profiles in many styles, 
                  widths,&nbsp;and&nbsp;textures.
                </p>
              </Col>
              <Col sm='4'>
                <Card><Img fluid={data.certainteedLogo.childImageSharp.fluid} className="card-img"/></Card>
              </Col>
            </Row>
            <Row>
              <Col className='cg-thumbnail-container'>
                {thumbnails.map( (item,key) =>
                <Card key={item.id} style={{width:200, margin:5}}>
                  <Img 
                    fixed={item.thumbnail}
                    title={item.name}
                    alt={item.name}
                    className="card-img"/>
                  <Card.Body>
                    <Card.Title>{item.name}</Card.Title>
                    <Card.Text>{item.description}</Card.Text>
                  </Card.Body>
                </Card>
                )}
              </Col>
            </Row>
            <Row>
              <Col sm style={{marginTop:'2rem'}}>
                <p>
                  Uncertain which siding type or color would best compliment your 
                  home?  We've been helping homeowners make great
                  style decisions for years. <a href="#contactForm" className='cg-linkDark'>Contact us</a> - we 
                  want your house to look&nbsp;it's&nbsp;best.
                </p>
              </Col>
            </Row>
            <Row>
              <Col sm style={{marginTop:'2rem'}}>
                <Link className='btn btn-secondary' to='/portfolio-siding'>Siding Portfolio</Link>
              </Col>
              <Col sm style={{marginTop:'2rem'}}>
                <Button className="cg-dark" href="#contactForm" >Get a Quote</Button>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      
      <Hr img={data.wideDivider4.childImageSharp.fluid} style={{ maxHeight: '30vh'}}/>
      
      <div id='plan'  className='cg-sectionLight'>
        <div className='cg-section'>
          <Container fluid>
            <Row>
              <Col>
                <SectionHeading>Problem Solved</SectionHeading>
              </Col>
            </Row>
            <Row>
              <Col>
                <PlanSteps />
                <Button className="cg-dark" href="#contactForm">Get Started with a Quote</Button>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      
      <Hr img={data.wideDivider5.childImageSharp.fluid} />
      
      <div id='paragraph' className='cg-sectionShaded'>
        <div className='cg-section'>
          <Container fluid>
            <Row>
              <Col>
                <SectionHeading>About MLB&nbsp;Contracting</SectionHeading>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className='d-block d-sm-none cg-mini-picture-box'>
                  <Img fluid={data.paragraph2.childImageSharp.fluid} className='cg-picture'/>
                 </div>
                <p>
                  At MLB Contracting we know you want to feel good about the condition
                  of your house. We believe homeowners
                  should be able to feel good about how their house looks without the expense of
                  repainting year after year. This is why we have focused, trained and been certified 
                  to install vinyl siding according to industry standards. We have 
                  a <Link to="/reviews" className='cg-linkDark'>track record</Link> that 
                  ensures your success. We make it easy for you to protect your home,
                  while at the same time refreshing curb-appeal: 
                  1.Get a quote 2.We'll contact you to discuss project details 3.We'll 
                  schedule a start date that works for you. So,  <a href="#contactForm" className='cg-linkDark'>request a quote</a> today. 
                  {/* TODO LEADGEN 
                  And in the meantime, learn 
                  about <Link to="/common-roofing-and-siding-problems" className='cg-linkDark'>common siding problems</Link>. 
                  */}
                  So you can stop worrying about peeling paint and instead enjoy a 
                  beautiful&nbsp;home.
                </p>
              </Col>
              <Col sm={4} md={5} lg={6} xl={7} className='d-none d-sm-block'>
                <Img fluid={data.paragraph2.childImageSharp.fluid} className='cg-picture'/>
                <Img fluid={data.paragraph1.childImageSharp.fluid} className='cg-picture' style={{marginTop:'2rem'}}/>
              </Col>
            </Row>
            <Row>
              <Col sm style={{marginTop:'2rem'}}>
                {/* TODO LEADGEN 
                <Button variant='secondary' href="/common-roofing-and-siding-problems" >Common Siding Problems</Button>
                */}
                <Link className='btn btn-secondary' to='/reviews#main'>Reviews</Link>
                {/* TODO LEADGEN replace button above */}
              </Col>
              <Col sm style={{marginTop:'2rem'}}>
                <Button className="cg-dark" href="#contactForm" >Get a Quote</Button>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      
      <Hr img={data.wideDivider6.childImageSharp.fluid} style={{ maxHeight: '30vh'}}/>
      
    </main>
    
    <ContactForm message={contactTxt}/> 
    
        
    <div id='footnotes' className='cg-sectionLight'>
      <div className='cg-section'>
        <Container fluid>
          <Row>
            <Col style={{ textAlign:'center', color:'#aaa'}}>
              <small>All construction photos are from MLB Contracting projects. CertainTeed siding option photos are from certainteed.com</small>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
    
  </Layout>
)};


export default Siding;
